import { useContext } from "react"
import { GlobalContext } from './_app'

import { Header } from "../fuselage/global/header/header"
import { Footer } from '../fuselage/theme/2024/layout/footer/footer'
import { HeaderShim } from '../fuselage/global/header/header'

export default function ErrorPage () {

    const globals = useContext(GlobalContext)

    return (
        <>
            <Header mainMenu={globals.nodes} />
            <HeaderShim />
            <section className="block">
                <div className="container">
                    
                    <p className="fs-0 fw-400 c-primary">404</p>
                    <p className='fw-400'>Page not found. Please check your URL.</p>

                </div>
            </section>
            <Footer 
				socialConnections={globals.globalSets[2].socialConnections} 
				contactInfo={globals.globalSets[2].contactDetails[0].body}
				hubspotForm={globals.globalSets[2].hubspotForm[0] ? globals.globalSets[2].hubspotForm[0] : null}
			/>
        </>
    )
}
ErrorPage.theme = 'aviadev-2024'